import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import SEO from "../components/SEO"
import { TitleAndDescription, PageSegment } from "../components"
import { GridOneColumn } from "../styles"

import facebookIcon from "../assets/images/facebook-icon.svg"
import instagramIcon from "../assets/images/instagram-icon.svg"

const Banner = styled.div`
  display: none;

  @media (min-width: 768px) {
    width: 100%;
    display: block;
    grid-column: 1/-1;
  }
`

const ProfileContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-bottom: 50px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1.5fr;
  }
`

const ImageProfileContainer = styled.div`
  width: 275px;
  height: 275px;

  @media (min-width: 768px) {
    justify-self: center;
    align-self: center;
    margin: 20px;
    width: 340px;
    height: 340px;
  }

  @media (min-width: 1024px) {
    width: 400x;
    height: 400x;
  }
`

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--grey);
  width: 90%;
  max-width: 700px;

  @media (min-width: 768px) {
    align-self: flex-start;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 10px;
    margin-top: 30px;
  }
`

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70px;
  margin-bottom: 15px;
  align-items: center;

  @media (min-width: 1024px) {
    margin-bottom: 0px;
  }
`
const Icon = styled.a`
  width: 30px;
  height: 30px;

  img {
    width: 30px;
    height: 30px;
  }
`

const ContainerGallery = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    grid-column: 1/-1;
    justify-self: center;
    justify-content: center;
  }
`

const ImageContainerGallery = styled.div`
  width: 90%;
  margin: 10px 10px;

  @media (min-width: 768px) {
    width: 360px;
  }
`
const TitleAndDescriptionProfile = styled(TitleAndDescription)`
  @media (min-width: 768px) {
    width: 100%;
  }
`
export default function CreativePage({ data: { creative } }: { data: any }) {
  return (
    <>
      <SEO title={creative.name} />
      <PageSegment background="var(--white)">
        <ProfileContainer>
          <Banner>
            <Img
              fluid={creative.profileBanner.asset.fluid}
              alt={creative.name}
            />
          </Banner>
          <ImageProfileContainer>
            <Img
              fluid={creative.profilePicture.asset.fluid}
              alt={creative.name}
            />
          </ImageProfileContainer>
          <TextContainer>
            <TitleAndDescriptionProfile title={creative.name} rightSide={false}>
              <p>{creative.description}</p>
            </TitleAndDescriptionProfile>
            <ContactInfoContainer>
              <p>{creative.email}</p>
              <p>{creative.site}</p>
              <IconContainer>
                <Icon href={creative.facebook}>
                  <img src={facebookIcon} alt={`facebook-${creative.nam}`} />
                </Icon>
                <Icon href={creative.instagram}>
                  <img src={instagramIcon} alt={`instagram-${creative.nam}`} />
                </Icon>
              </IconContainer>
            </ContactInfoContainer>
          </TextContainer>
          <ContainerGallery>
            {creative.imagesGallery.map((image: any) => {
              return (
                <ImageContainerGallery key={image.asset._id}>
                  <Img fluid={image.asset.fluid} alt={creative.name} />
                </ImageContainerGallery>
              )
            })}
          </ContainerGallery>
        </ProfileContainer>
      </PageSegment>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    creative: sanityCreative(slug: { current: { eq: $slug } }) {
      id
      name
      description
      site
      email
      instagram
      facebook
      profilePicture {
        asset {
          fluid(maxHeight: 400, maxWidth: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
      imagesGallery {
        asset {
          _id
          fluid(maxHeight: 248, maxWidth: 360) {
            ...GatsbySanityImageFluid
          }
        }
      }
      profileBanner {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
